import React from 'react';
import { NovatedLeaseContainer, OverallSavingContainer } from './styled';
import { Container } from 'react-bootstrap';

const OverallSaving = ({ id, amount, description }) => {
    return (
        <OverallSavingContainer>
            <h1>{amount}</h1>
            <p>{description}</p>
        </OverallSavingContainer>
    );
};

const NovatedLease = () => {
    const overallSavings = [
        {
            id: 1,
            amount: '26%',
            description:
                'Less total cost over life versus an equivalent ICE vehicle (same drive-away price of $75,400). Nearly $32,000 worth of savings across a 5 year lease term.',
        },
        {
            id: 2,
            amount: '19%',
            description:
                'Less total cost over life versus the same car on a personal loan. Over $21,000 worth of savings across a 5 year lease/loan term.',
        },
        {
            id: 3,
            amount: '48%',
            description:
                'Less monthly running costs versus an equivalent ICE vehicle (or up to $3,400/year)',
        },
    ];
    return (
        <NovatedLeaseContainer>
            <Container className="no-pd-col">
                <div className="title">
                    <h2>Novated Savings Calculator</h2>
                    <h4>
                        Novated leasing offers several benefits,
                        particularly for employees looking to acquire a
                        vehicle through a cost-effective and convenient
                        process.
                    </h4>
                </div>
                <div className="saving-title-container">
                    <h3>
                        Overall savings for a Novated lease on a Polestar 2 Standard range Single motor
                    </h3>
                </div>
                <div className="saving-container">
                    {overallSavings.map((data) => (
                        <OverallSaving
                            key={data.id}
                            amount={data.amount}
                            description={data.description}
                        />
                    ))}
                </div>
            </Container>
        </NovatedLeaseContainer>
    );
};

export default NovatedLease;
