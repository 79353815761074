import React from 'react';
import { ButtonWrapper } from './styled';
import { Image } from 'react-bootstrap';

const Button = ({
    children,
    text,
    onClick,
    type,
    icon,
    style,
    disabled,
    buttonType,
    iconStyle,
    textStyle,
}) => {
    return (
        <ButtonWrapper
            onClick={onClick}
            type={type}
            style={style}
            disabled={disabled}
            buttonType={buttonType}
        >
            <div>
                {children}
                {text && <span style={textStyle}>{text}</span>}

                {icon && (
                    <Image
                        alt="icon"
                        src={icon}
                        className="icon-button"
                        style={iconStyle}
                    />
                )}
            </div>
        </ButtonWrapper>
    );
};

export default Button;
