import React, { useState, useRef, useEffect } from 'react';
import { PolestarCalculatorWrappper } from '../../common/LeaseCalculator/LeaseCalculatorStyled';
import { Col, Image, Row, Container } from 'react-bootstrap';
import CustomSelect from './components/Dropdown/Dropdown';
import { RangeSlider } from './components/RangeSlider';
import { Meter } from './components//Meter';
import 'react-multi-carousel/lib/styles.css';
import { connect } from 'react-redux';
import {
    stateOptions,
    polestarCars,
    arrowButton,
    backArrow,
} from '../../utils/helpers';
import {
    getMakes,
    getModels,
    getModelYears,
    getBodyType,
    getVariants,
    getVariant,
    getQuote,
} from '../../actions/VehicleActions';
import { Formik, Form } from 'formik';
import { PackageCard } from './components/PackageCard';

import { PolestarCalcStyled } from './styled';
import Button from './components/Button/Button';
import { Quote } from './components/FormQuote/PolestarQuote';
import { ErrorText } from './components/Error';
import { PackCard } from './components/PackCard';
import { PackSkeleton } from './components/Skeleton';
import { Slider } from './components/Slider';
import NovatedLease from './components/NovatedLease/NovatedLease';
import { CompareNovatedLease } from './components/CompareNovatedLease';

const LeaseCalculatorContainer = ({
    getMakes,
    makesOptions,
    getModels,
    modelOptions,
    getModelYears,
    getBodyType,
    modelYearOptions,
    bodyTypeOptions,
    getVariants,
    variantOptions,
    getVariant,
    getQuote,
    list_price_gross,
    saving_per_year,
    cost_per_fortnight,
    photo_url,
    quote,
    fortnight_car_cost,
    fortnight_running_cost,
    isElectric,
    shareOfSavings,
    perFortnight,
    isLoading,
    pack_package,
}) => {
    const currentURL = window.location.pathname;
    const pattern = /\/ev\/?$/;
    const isEv = pattern.test(currentURL);

    // const [activeTab, setActiveTab] = useState('detailed');
    const activeTab = 'detailed';
    const fuel_flag = isElectric ? 'no' : 'yes';
    const slug = window.location.pathname.substring(1);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showQuote, setShowQuote] = useState(false);
    const componentRef = useRef(null);
    const [cardId, setCardId] = useState(0);
    const [packId, setPackId] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [carChosen, setCarChosen] = useState(false);
    const [values, setValues] = useState({});
    const [selectPack, setSelectPack] = useState([]);
    const [totalPrice, setTotalPrice] = useState(
        parseInt(list_price_gross)
    );




    useEffect(()=>{
    if(values.variants) getVariant(values.variants,values);     
    // eslint-disable-next-line 
    },[getVariant, values.variants]) 

    const initialValues = {
        vehicle_make: '',
        vehicle_model: '',
        vehicle_size: '',
        year: '',
        body_type: '',
        variants: '',
        salary: 90000,
        travelDistance: 15000,
        price_range: 40000,
        leaseTerm: 5,
        state: 'VIC',
        customSelectField: 'Victoria',
        packs: [],
    };

    const selectPackHandle = (packages) => {
        let packIndex = selectPack?.findIndex(
            (pack) => pack.pack === packages.pack
        );
        let package_list = selectPack;
        if (packIndex >= 0) {
            package_list.splice(packIndex, 1);
            setSelectPack([...package_list]);
        } else if (packages.pack === 'Standard') {
            package_list = [];
            setSelectPack([packages]);
        } else {
            package_list = [
                ...(selectPack[0]?.pack !== 'Standard' ? selectPack : []),
                packages,
            ];
            setSelectPack(package_list);
        }
        let initialValues = parseInt(list_price_gross);
        let newSum = package_list?.reduce((acc, pack) => {
            let packInt = parseInt(pack?.pack_price);
            return acc + packInt;
        }, initialValues);
        setTotalPrice(newSum);
        getQuote(
            values.leaseTerm,
            values.variants,
            newSum,
            values.travelDistance,
            values.salary,
            values.state,
            fuel_flag,
            slug,
            isEv
        );
    };


    const scrollToComponent = () => {
        if (carChosen) setShowQuote(!showQuote);
        else {
            setCarChosen(false);
            setShowQuote(!showQuote);
        }

    };

    const checkOptions = (stateOptions, value) => {
        const index = stateOptions.findIndex((opt) => {
            return opt.value === value;
        });
        return stateOptions[index]?.label;
    };

    useEffect(() => {
        if (!carChosen && showQuote) {
            document
                .getElementById('calculator')
                .scrollIntoView({ behavior: 'smooth' });
            setShowQuote(false);
        }
        if (showQuote && carChosen) {
            componentRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [showQuote, carChosen]);

    const scrollToOptionPack = (optionId) => {
        document.getElementById(optionId)?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        });
    };

    useEffect(() => {
        scrollToOptionPack(`pack-${packId}`);     
    }, [packId]);   

    return (
        <PolestarCalcStyled>
            <NovatedLease />
            <PolestarCalculatorWrappper>
                <Formik enableReinitialize initialValues={initialValues}>
                    {(props, setFieldValue) => {
                        const { values } = props;
                        setValues(values);
                        return (
                            <Form
                                onKeyDown={(e) => {
                                    if ((e.charCode || e.keyCode) === 13) {
                                        e.preventDefault();
                                    }
                                }}
                            >
                                <section
                                    className="Lease-section"
                                    id={'calculator'}
                                >
                                    <Container
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Row className="row-container">
                                            <Col className="flex sub-title-heading no-pd space-between item-center">
                                                <h4>
                                                    Select a vehicle to
                                                    calculate your savings
                                                </h4>
                                                <div className='flex source-container'>
                                                    <p>
                                                    Powered by
                                                    </p>
                                                    <img src="/Images/logo-easi.svg" alt="logo" />
                                                </div>
                                            </Col>
                                            <Row
                                                style={{
                                                    display: 'grid',
                                                    position: 'relative',
                                                }}
                                            >
                                                <Col className="col-overflow ">
                                                    <PackageCard
                                                        imgSrc="/Images/polestar-car.png"
                                                        options={
                                                            polestarCars
                                                        }
                                                        value={
                                                            values.variants
                                                        }
                                                        cardId={cardId}
                                                        setIsEmpty={
                                                            setIsEmpty
                                                        }
                                                        setCardId={
                                                            setCardId
                                                        }
                                                        setPackId={
                                                            setPackId
                                                        }
                                                        setCarChosen={
                                                            setCarChosen
                                                        }
                                                        customHandle={(
                                                            variant_id
                                                        ) => {
    
                                                            getVariant(
                                                                variant_id,
                                                                values,
                                                                null,
                                                                slug,
                                                                isEv
                                                            );
                                                        }}
                                                        name="variants"
                                                        slug
                                                        setSelectPack={
                                                            setSelectPack
                                                        }
                                                        
                                                    />
                                                </Col>
                                                <Col
                                                    className='slider-container'
                                                >
                                                    <div className="button-container">
                                                        <Button
                                                            buttonType="secondary"
                                                            onClick={() =>
                                                                setCardId(
                                                                    cardId -
                                                                        1
                                                                )
                                                            }
                                                            disabled={
                                                                cardId ===
                                                                0
                                                            }
                                                            style={{
                                                                margin: '0px',
                                                                padding:
                                                                    '8px',
                                                            }}
                                                            icon={
                                                                backArrow
                                                            }
                                                            iconStyle={{
                                                                margin: '0',
                                                            }}
                                                        />
                                                        <Button
                                                            buttonType="secondary"
                                                            onClick={() =>
                                                                setCardId(
                                                                    cardId +
                                                                        1
                                                                )
                                                            }
                                                            disabled={
                                                                cardId ===
                                                                2
                                                            }
                                                            style={{
                                                                margin: '0px',
                                                                padding:
                                                                    '8px',
                                                                marginLeft:
                                                                    '18px',
                                                            }}
                                                            icon={
                                                                arrowButton
                                                            }
                                                            iconStyle={{
                                                                margin: '0',
                                                            }}
                                                        />
                                                    </div>
                                                    <Slider
                                                        totalImages={
                                                            polestarCars.length
                                                        }
                                                        activeIndex={
                                                            cardId
                                                        }
                                                        style={{
                                                            width: '50%',
                                                            marginLeft:
                                                                '25px',
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row                                                
                                                className="pack-row"
                                                style={{overflowX:'auto',
                                                    opacity: carChosen
                                                        ? '1'
                                                        : '0.4',
                                                    pointerEvents:
                                                        carChosen
                                                            ? ''
                                                            : 'none',
                                                }}
                                            >
                                                <Col className="pack-container">
                                                    <Col className="sub-title-heading pack-title no-pd">
                                                        <h4>
                                                            Upgrade your
                                                            vehicle pack
                                                            <span>
                                                                {' '}
                                                                (select
                                                                multiple)
                                                            </span>
                                                        </h4>
                                                    </Col>   
                                                      {!carChosen ? (
                                                        <Col
                                                            className="no-pd"
                                                            style={{
                                                                display:
                                                                    'flex',
                                                                gap: '24px',
                                                            }}
                                                        >
                                                            <PackSkeleton />
                                                            <PackSkeleton />
                                                            <PackSkeleton />
                                                            <PackSkeleton />
                                                        </Col>
                                                    ) :    
                                                                    <>
                                                            {pack_package ? (
                                                                <Col
                                                                    className="col-pack"
                                                                    style={{
                                                                        paddingLeft:
                                                                            '1px',
                                                                    }}
                                                                >
                                                                    {pack_package?.map(
                                                                        (
                                                                            pack,
                                                                            index
                                                                        ) => (
                                                                            <PackCard
                                                                                index={
                                                                                    index
                                                                                }
                                                                                name="packs"
                                                                                selectPack={
                                                                                    selectPack
                                                                                }
                                                                                pack={
                                                                                    pack
                                                                                }
                                                                                selectPackHandle={
                                                                                    selectPackHandle
                                                                                }
                                                                                value={
                                                                                    values.packs
                                                                                }
                                                                                setPackId={
                                                                                    setPackId
                                                                                }
                                                                                packId={
                                                                                    packId
                                                                                }
                                                                                
                                                                            />
                                                                        )
                                                                    )}
                                                                </Col>
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        gap: '15px',
                                                                    }}
                                                                >
                                                                    <PackSkeleton />
                                                                    <PackSkeleton />
                                                                    <PackSkeleton />
                                                                    <PackSkeleton />
                                                                </div>
                                                            )}
                                                            <Col
                                                                className='slider-container'
                                                            >
                                                                <div className="button-container">
                                                                    <Button
                                                                        buttonType="secondary"
                                                                        onClick={() => {
                                                                            setPackId(
                                                                                packId -
                                                                                    1
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            packId ===
                                                                            0
                                                                        }
                                                                        style={{
                                                                            margin: '0px',
                                                                            padding:
                                                                                '8px',
                                                                        }}
                                                                        icon={
                                                                            backArrow
                                                                        }
                                                                        iconStyle={{
                                                                            margin: '0',
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        buttonType="secondary"
                                                                        onClick={() => {
                                                                            setPackId(
                                                                                packId +
                                                                                    1
                                                                            );
                                                                        }}
                                                                        disabled={
                                                                            packId ===
                                                                            pack_package?.length -
                                                                                1
                                                                        }
                                                                        style={{
                                                                            margin: '0px',
                                                                            padding:
                                                                                '8px',
                                                                            marginLeft:
                                                                                '18px',
                                                                        }}
                                                                        icon={
                                                                            arrowButton
                                                                        }
                                                                        iconStyle={{
                                                                            margin: '0',
                                                                        }}
                                                                    />
                                                                </div>
                                                                <Slider
                                                                    totalImages={
                                                                        pack_package?.length
                                                                    }
                                                                    activeIndex={
                                                                        packId
                                                                    }
                                                                    style={{
                                                                        marginLeft:
                                                                            '25px',
                                                                    }}
                                                                />
                                                            </Col>
                                                        </>
                                                       
                                                                }
                                                    <Col className="no-pd">
                                                        {carChosen ===
                                                            false && (
                                                            <ErrorText
                                                                type="text"
                                                                text="Car type must be chosen"
                                                            />
                                                        )}
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Row>
                                        <Row
                                            style={{
                                                paddingRight:'15px',
                                                justifyContent:
                                                    'space-between',
                                            opacity: carChosen && selectPack.length > 0
                                                ? '1'
                                                : '0.4',
                                            pointerEvents:
                                                carChosen && selectPack.length > 0
                                                    ? ''
                                                    : 'none',
                                        }}
                                        >
                                            <Col xs={12} lg={6}>
                                                <Row className="state-row">
                                                    <Col className="no-pd">
                                                        <div className="state-label">
                                                            <span>
                                                                Selected:
                                                            </span>{' '}
                                                            <br />
                                                            <span>
                                                                {checkOptions(
                                                                    stateOptions,
                                                                    values.state
                                                                )}
                                                            </span>
                                                        </div>

                                                        <div className="state-cont">
                                                            <p
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    display:
                                                                        'flex',
                                                                }}
                                                                onClick={() =>
                                                                    setShowDropdown(
                                                                        !showDropdown
                                                                    )
                                                                }
                                                            >
                                                                Change
                                                                state
                                                                <span className="state-change">
                                                                    <Image
                                                                        alt="icon"
                                                                        src="/Images/arrow-right.svg"
                                                                        className="img-icon"
                                                                    />
                                                                </span>
                                                            </p>
                                                            {showDropdown && (
                                                                <CustomSelect
                                                                    setShowDropdown={
                                                                        setShowDropdown
                                                                    }
                                                                    name="state"
                                                                    options={
                                                                        stateOptions
                                                                    }
                                                                    value={
                                                                        values.stateOptions
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="no-pd">
                                                        <RangeSlider
                                                            // disabled={isAllSelected()}
                                                            min={20000}
                                                            headtext={
                                                                'What is your annual salary?'
                                                            }
                                                            current={
                                                                values.salary
                                                            }
                                                            step={1000}
                                                            max={200000}
                                                            before={'$'}
                                                            name="salary"
                                                            overMax
                                                            handleFinalChange={(
                                                                annual_salary,
                                                                lease_period = values.leaseTerm,
                                                                variant_id = values.variants,
                                                                approximate_price = activeTab ===
                                                                'basic'
                                                                    ? values.price_range
                                                                    : totalPrice,
                                                                annual_kilometres = values.travelDistance,
                                                                state = values.state
                                                            ) => {
                                                                if (
                                                                    !variant_id
                                                                ) {
                                                                    setIsEmpty(
                                                                        true
                                                                    );
                                                                    return;
                                                                }
                                                                getQuote(
                                                                    lease_period,
                                                                    variant_id,
                                                                    approximate_price,
                                                                    annual_kilometres,
                                                                    annual_salary,
                                                                    state,
                                                                    fuel_flag,
                                                                    slug,
                                                                    isEv
                                                                );
                                                            }}
                                                        />
                                                        <RangeSlider
                                                            // disabled={isAllSelected()}
                                                            min={5000}
                                                            headtext={
                                                                'How far will you travel in a year?'
                                                            }
                                                            current={
                                                                values.travelDistance
                                                            }
                                                            step={1000}
                                                            max={40000}
                                                            after={'km'}
                                                            name="travelDistance"
                                                            handleFinalChange={(
                                                                annual_kilometres,
                                                                lease_period = values.leaseTerm,
                                                                variant_id = values.variants,
                                                                approximate_price = activeTab ===
                                                                'basic'
                                                                    ? values.price_range
                                                                    : totalPrice,
                                                                annual_salary = values.salary,
                                                                state = values.state
                                                            ) => {
                                                                if (
                                                                    !variant_id
                                                                ) {
                                                                    setIsEmpty(
                                                                        true
                                                                    );
                                                                    return;
                                                                }
                                                                getQuote(
                                                                    lease_period,
                                                                    variant_id,
                                                                    approximate_price,
                                                                    annual_kilometres,
                                                                    annual_salary,
                                                                    state,
                                                                    fuel_flag,
                                                                    slug,
                                                                    isEv
                                                                );
                                                            }}
                                                        />

                                                        <RangeSlider
                                                            // disabled={isAllSelected()}
                                                            min={1}
                                                            headtext={
                                                                'Preferred length of lease term?'
                                                            }
                                                            current={
                                                                values.leaseTerm
                                                            }
                                                            step={1}
                                                            max={5}
                                                            after={'Years'}
                                                            name="leaseTerm"
                                                            handleFinalChange={(
                                                                lease_period,
                                                                variant_id = values.variants,
                                                                approximate_price = activeTab ===
                                                                'basic'
                                                                    ? values.price_range
                                                                    : totalPrice,
                                                                annual_kilometres = values.travelDistance,
                                                                annual_salary = values.salary,
                                                                state = values.state
                                                            ) => {
                                                                if (
                                                                    !variant_id
                                                                ) {
                                                                    setIsEmpty(
                                                                        true
                                                                    );
                                                                    return;
                                                                }
                                                                getQuote(
                                                                    lease_period,
                                                                    variant_id,
                                                                    approximate_price,
                                                                    annual_kilometres,
                                                                    annual_salary,
                                                                    state,
                                                                    fuel_flag,
                                                                    slug,
                                                                    isEv
                                                                );
                                                            }}
                                                        />
                                                        {isEmpty && (
                                                            <ErrorText
                                                                type="text"
                                                                text="Car type must be chosen"
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col
                                                xs={12}
                                                lg={4}
                                                className="meter-row no-pd"
                                                id="slider"
                                            >
                                                <Meter
                                                    heading1={
                                                        'Annual Tax Savings'
                                                    }
                                                    text1={
                                                        <>
                                                            {
                                                                'Total annual estimated savings are based on a salary of '
                                                            }
                                                            <b>
                                                                $
                                                                {values.salary.toLocaleString(
                                                                    'en-US'
                                                                )}
                                                            </b>
                                                            ,
                                                            <b>
                                                                {' '}
                                                                {values.travelDistance.toLocaleString(
                                                                    'en-US'
                                                                )}
                                                                km
                                                            </b>{' '}
                                                            per year and a{' '}
                                                            <b>
                                                                {
                                                                    values.leaseTerm
                                                                }{' '}
                                                                year
                                                            </b>{' '}
                                                            lease.
                                                        </>
                                                    }
                                                    heading2={
                                                        'Weekly Cost'
                                                    }
                                                    text2={
                                                        'Total estimated weekly cost'
                                                    }
                                                    annual={Math.round(
                                                        saving_per_year ??
                                                            0
                                                    )}
                                                    fortnight={Math.round(
                                                        (cost_per_fortnight ??
                                                            0) / 2
                                                    )}
                                                    shareOfSavings={
                                                        shareOfSavings
                                                    }
                                                    perFortnight={
                                                        perFortnight
                                                    }
                                                    slug={slug}
                                                    selectPack={selectPack}
                                                    packId={packId}
                                                    carChosen={carChosen}
                                                />
                                                <Button
                                                    text={'Get a Quote'}
                                                    icon={arrowButton}
                                                    onClick={
                                                        scrollToComponent
                                                    }
                                                    style={{
                                                        marginLeft: '0px',
                                                        border: 'none',
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                            </Form>
                        );
                    }}
                </Formik>
                <section className="Lease-section" ref={componentRef}>
                    <div
                        ref={componentRef}
                        style={{
                            display:
                                showQuote && carChosen ? 'block' : 'none',
                        }}
                    >
                        <Quote
                            setShowQuote={setShowQuote}
                            showQuote={showQuote}
                            state={{
                                leaseData: values,
                                cost_per_fortnight,
                                saving_per_year,
                                fortnight_car_cost,
                                fortnight_running_cost,
                                isEv: isEv,
                            }}
                        />
                    </div>
                </section>
            </PolestarCalculatorWrappper>
            <CompareNovatedLease cars={values} />
        </PolestarCalcStyled>
    );
};
const mapStateToProps = (state) => {
    const quote = state.vehicle.quote;
    const variant = state.vehicle.variant;
    return {
        isCalculating: state.vehicle.isCalculating,
        isLoading: state.vehicle.isLoading,
        makesOptions: state.vehicle.make_options,
        modelOptions: state.vehicle.model_options,
        modelYearOptions: state.vehicle.model_years_options,
        bodyTypeOptions: state.vehicle.body_type_options,
        variantOptions: state.vehicle.variant_options,
        list_price_gross: variant.list_price_gross || 63900.00,
        saving_per_year: quote ? quote.advantage_per_year : null,
        cost_per_fortnight: quote
            ? parseInt(quote.after_tax_effect_per_pay_cycle)
            : null,
        fortnight_car_cost: quote
            ? parseInt(quote.after_tax_effect_per_pay_cycle) -
              (parseInt(quote.reg_per_pay_cycle_gross) +
                  parseInt(quote.maint_per_pay_cycle_gross) +
                  parseInt(quote.t_per_pay_cycle_gross) +
                  parseInt(quote.rsa_per_pay_cycle_gross) +
                  parseInt(quote.ins_per_pay_cycle_gross) +
                  parseInt(quote.f_per_pay_cycle_gross) +
                  parseInt(quote.mfee_per_pay_cycle_gross))
            : null,
        fortnight_running_cost: quote
            ? parseInt(quote.reg_per_pay_cycle_gross) +
              parseInt(quote.maint_per_pay_cycle_gross) +
              parseInt(quote.t_per_pay_cycle_gross) +
              parseInt(quote.rsa_per_pay_cycle_gross) +
              parseInt(quote.ins_per_pay_cycle_gross) +
              parseInt(quote.mfee_per_pay_cycle_gross) +
              parseInt(quote.f_per_pay_cycle_gross)
            : null,
        photo_url: variant ? variant.photo : null,
        isElectric: variant
            ? parseFloat(variant.engine_litres) === 0 &&
              parseFloat(variant.fuel_metro) === 0
            : false,
        quote: quote,
        shareOfSavings: quote?.share_of_savings,
        perFortnight: quote?.share_of_savings_per_pay_cycle,
        pack_package: variant?.pack_package,
    };
};

export const PolestarCalculator = connect(mapStateToProps, {
    getMakes,
    getModels,
    getModelYears,
    getBodyType,
    getVariants,
    getVariant,
    getQuote,
})(LeaseCalculatorContainer);
