import styled from 'styled-components';
import { theme } from '../../../../theme';
export const MeterWrappper = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    background: white;
    width: min-content;
    padding: 0px 16px 0px 0px;
    .heading {
        font-size: ${theme.fontSizes.lg} !important;
        line-height: 19.2px;
    }
    .text {

        font-size: ${theme.fontSizes.md};
        margin: 0px;
        letter-spacing: 0.1px;
        line-height: 16.8px;
    }
    .text-description {

        font-size: ${theme.fontSizes.md};
        margin: 16px 0px 40px;
        letter-spacing: 0.1px;
        line-height: 16.8px;
    }
    h4 {
        color: #F97500;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        margin: 0px 4px 0px 0px;
        background: #F5F5F5;
        max-width: 56px;
        width: 56px;
        height: 64px;
        padding: 14px 0px;
    }
    span {
        color: #F97500;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Roboto;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        margin: 0px 4px;
        background: #F5F5F5;
        width: 56px;
        height: 64px;
        padding: 8px 0px;
        max-width: 56px;
        
    }
    .amount {
        display: flex;
        margin: 12px 0px;

    }
    hr {
        border-color: white;
    }
    ${theme.mediaQueriesMaxWidth.md} {
        width: 270px;
        p {
            margin-left: 10px;
        }
    }
    ${theme.mediaQueriesMaxWidth.sm} {
        width: auto;
    }

    @property --num {
        syntax: '<integer>';
        initial-value: 0;
        inherits: false;
    }

    .numAnimate {
        animation: counter 0.5s infinite alternate ease-in-out;
        counter-reset: num var(--num);
        padding: 0;
    }
    .numAnimate::after {
        content: counter(num);
    }
    .revNumAnimate {
        animation: revCounter 0.5s infinite alternate ease-in-out;
        counter-reset: num var(--num);
        padding: 0;
    }

    .revNumAnimate::after {
        content: counter(num);
    }
    .lastNumAnimate {
        animation: lastCounter 0.5s infinite alternate ease-in-out;
        counter-reset: num var(--num);
        padding: 0;
    }

    .lastNumAnimate::after {
        content: counter(num);
    }
    @keyframes lastCounter {
        from {
            --num: 4;
        }
        to {
            --num: 8;
        }
    }

    @keyframes revCounter {
        from {
            --num: 9;
        }
        to {
            --num: 0;
        }
    }
    @keyframes counter {
        from {
            --num: 0;
        }
        to {
            --num: 9;
        }
    }
`;

export const SubCosts = styled.div`
    display: flex;
    justify-content: space-between;
    color: ${theme.colors.white};
    margin-top: ${theme.space.sm};
`;

export const Cost = styled.div`
    flex: 1;
    text-align: center;
    font-size: ${theme.fontSizes.xs};
    font-family: ${theme.fonts.Replica_bold};
    p {
        margin-bottom: 4px;
    }
    h3 {
        font-size: 2.33rem;
        span {
            padding: 0px;
            margin: 0px;
            background: transparent;

        }
    }
`;
