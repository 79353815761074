import styled from 'styled-components';
import { theme } from '../../../../theme';

export const MessageFormContainer = styled.div`
    display: flex;
    height: 100%;
    gap: 2.5em;
    text-align: left;
    margin: 0px;
    font-family: Unica 77 Trial;

    .container-form {
        max-width: 1400px;
        margin-bottom: 50px;
        .no-pd-col {
            padding-right: 0px;
            padding-left: 0px;
        }
    }

`;
export const QuoteContainer = styled.div`
    padding: 5em;
    height: 100%;
    background: #ecece7;

    h3 {
        font-size: 32px;
    }

    ${theme.mediaQueriesMaxWidth.sm}{
        padding: 2em;
    }
`;

export const FormContainer = styled.div`
    display: grid;
    padding: 5em;
    ${theme.mediaQueriesMaxWidth.sm}{
        padding: 2em;
    }
    p {
        font-weight: 400;
    }

    .quote-success {
        padding-bottom: 350px;
        h2 {
            color: black;
            font-size: 32px;
        }
        color: black;
        p {
            margin-left: 15px;
        }
    }
`;

export const PolicyContainer = styled.div`
    height: 100%;
    padding: 0 5em 5em 5em;
    ${theme.mediaQueriesMaxWidth.sm}{
        padding: 0 2em 2em 2em;
    }
    position: relative;

    p {
        font-weight: 500;
        padding-bottom: 15px;    
        
        span {
            text-decoration-line: underline;
            color: #666666;
            font-weight: 400;

            a {
                color: #666666;
                font-weight: 400;
            }
        }
    }

`;

export const InputStyled = styled.div`
    padding: 0;
    position: relative;
    width: 100%;
    margin: 0 auto;
    color: #666666;
    font-weight: 400;
    padding-bottom: 20px;
    label {
        display: block;
        margin-bottom: 10px;
    }
    input {
        width: 100%;
        border: none;
        font-size: 16px;
        color: #666666;
        background: #f3f4f4;
        height: 48px;
        border-bottom: solid 1.5px #666666;
        border-radius: none;
        padding-left: 8px;
    }
    input:focus {
        outline: none;
    }
    .react-select__control {
        border: none;
        padding: 0;
        padding-right: 8px;
        border-radius: 0px;
        border-bottom: solid 1.5px #666666;
    }
    .react-select__value-container {
        padding: 0 8px 0 8px;
        height: 48px;
    }
    .react-select__indicator-separator {
        background-color: transparent;
    }
    .react-select__indicator {
        padding: 0 4px;
        svg {
            width: 30px;
            height: 30px;
        }
    }
    .react-select__single-value {
        color: #666666;
    }

    .react-select__placeholder {
        color: #cccccc;
        padding-left: 8px;
        font-size: 16px;
        font-weight: 400;
    }
`;
