import React from 'react'
import { Container } from 'react-bootstrap';
import { FooterContainer } from './styled'

const Footer = () => {
  return (
    <FooterContainer>
        <Container className="footer-container">
            <img src="/Images/logo-easi.svg" alt="logo" />
            <p>© 2024 Easifleet. All rights reserved. </p>
        </Container>
    </FooterContainer>
  )
}

export default Footer
