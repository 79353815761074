import styled from 'styled-components';
import { theme } from '../../../../theme';

export const FooterContainer = styled.div`
    background: black;
    padding: 24px 0;
    .footer-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        img{
            filter: invert(1);
        }
        p{
            color: white;
            height: max-content;
            margin: 0;
            font-size: 14px;

        }
        ${theme.mediaQueriesMaxWidth.sm} {
            flex-direction: column;
            align-items: flex-start;
            padding: 12px 15px;
            gap: 24px;

        }
    }
    
`