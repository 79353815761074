import styled from "styled-components";

export const DropdownWrappper = styled.div`

.select-options {
    list-style-type: none;
    background: #ECECE7;
    color: rgba(0, 0, 0, 0.60);
    width: fit-content;
    height: max-content;
    padding-inline-start: 0;
    padding: 16px;
    position: absolute;
    z-index: 1;
    line-height: 1.9rem;
    li {
        &:hover {
            color: black;
            cursor: pointer;
        }
      }
}


`