export const stateOptions = [
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' },
    { value: 'NSW', label: 'New South Wales' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'NT', label: 'Northern Territory' },
];

export const stateOptionsFull = [
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' },
    { value: 'NSW', label: 'New South Wales' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'NT', label: 'Northern Territory' },
];

export const timelineOptions = [
    { value: 'asap', label: 'ASAP' },
    { value: 'three_months', label: '3 Months' },
    { value: 'six_months', label: '6 Months' },
];

export const preSelectVariants = [
    { value: 168843, label: 'Small' },
    { value: 171627, label: 'Medium' },
    { value: 172131, label: 'Large' },
];

export const evOptions = [
    { value: '184234', label: 'BMW iX1' },
    { value: '183691', label: 'BYD Atto 3' },
    { value: '183693', label: 'BYD Atto 3 Extended Range' },
    { value: '182527', label: 'Cupra Born' },
    { value: '182530', label: 'Cupra Formentor VZe PHEV' },
    { value: '183384', label: 'Cupra Leon ZVe PHEV' },
    { value: '181787', label: 'Ford Escape ST-Line PHEV' },
    { value: '184557', label: 'GWM ORA Standard Range' },
    { value: '184558', label: 'GWM ORA Extended Range' },
    { value: '183272', label: 'Hyundai Ioniq 5 Dynamic' },
    { value: '183273', label: 'Hyundai Ioniq 5 Techniq' },
    { value: '183281', label: 'Hyundai Ioniq 5 Epiq' },
    { value: '184357', label: 'Hyundai Ioniq 6 Dynamic' },
    { value: '184363', label: 'Hyundai Ioniq 6 Techniq' },
    { value: '184363', label: 'Hyundai Ioniq 6 Epiq' },
    { value: '183819', label: 'Hyundai Kona Electric Elite' },
    { value: '183813', label: 'Hyundai Kona Electric Elite (Extended)' },
    { value: '183821', label: 'Hyundai Kona Electric Highlander' },
    { value: '183817', label: 'Hyundai Kona Electric Highlander (Extended)' },
    { value: '181917', label: 'Kia EV6' },
    { value: '184088', label: 'Kia EV6 Air' },
    { value: '184090', label: 'Kia EV6 GT-Line' },
    { value: '184092', label: 'Kia EV6 GT-Line AWD' },
    { value: '183264', label: 'Kia Sorento PHEV GT-Line (7 seats)' },
    { value: '182618', label: 'Kia Niro S Electric' },
    { value: '182621', label: 'Kia Niro Sport' },
    { value: '182615', label: 'Kia Niro Electric GT Line' },
    { value: '177748', label: 'Lexus UX300e Luxury' },
    { value: '177750', label: 'Lexus UX300e Sports Luxury' },
    { value: '183881', label: 'Mazda CX60 Evolve' },
    { value: '183883', label: 'Mazda CX60 Evolve PHEV' },
    { value: '183885', label: 'Mazda CX60 Evolve VISION' },
    { value: '183884', label: 'Mazda CX60 Evolve VISION PHEV' },
    { value: '183890', label: 'Mazda CX60 GT' },
    { value: '183888', label: 'Mazda CX60 GT D50E GT Hybrid' },
    { value: '183889', label: 'Mazda CX60 GT D50E GT Vision' },
    { value: '183886', label: 'Mazda CX60 GT G40E GT Hybrid' },
    { value: '183887', label: 'Mazda CX60 GT G40E GT Vision Hybrid' },
    { value: '183895', label: 'Mazda CX60 Azami' },
    { value: '181313', label: 'Mazda MX-30 Electric Astina' },
    { value: '181337', label: 'Mazda MX-30 Electric E35 Astina' },
    { value: '182963', label: 'MG ZS EV Exite' },
    { value: '182964', label: 'MG ZS EV Essence' },
    { value: '181948', label: 'MG HS PHEV Plus Excite' },
    { value: '181906', label: 'MG HS PHEV Plus Essence' },
    { value: '181343', label: 'Mini Countryman Plugin Hybrid Classic Edition' },
    { value: '181344', label: 'Mini Countryman Plugin Hybrid Yours Edition' },
    { value: '181474', label: 'MINI Electric 3D' },
    { value: '181445', label: 'MINI Electric 5D' },
    { value: '181637', label: 'Mitsubishi Eclipse Cross Hybrid EV Aspire' },
    { value: '181638', label: 'Mitsubishi Eclipse Cross Hybrid EV Exceed' },
    { value: '183765', label: 'Mitsubishi Outlander PHEV EV ES (5 seats)' },
    { value: '183767', label: 'Mitsubishi Outlander PHEV EV Aspire (5 seats)' },
    { value: '183769', label: 'Mitsubishi Outlander Exceed PHEV (7 seats)' },
    { value: '183771', label: 'Mitsubishi Outlander Exceed Tourer PHEV AWD (7 seats)' },
    { value: '183450', label: 'Mercedes-Benz EQA250' },
    { value: '182158', label: 'Nissan Leaf' },
    { value: '182159', label: 'Nissan Leaf e+' },
    { value: '184808', label: 'Peugeot 308 GT PHEV' },
    { value: '184854', label: 'Peugeot 3008 GT Sport Plug-In Hybrid' },
    { value: '181792', label: 'Peugeot 508 Fastback Plug-in Hybrid' },
    { value: '181887', label: 'Polestar 2 Standard Range Single Motor' },
    { value: '181886', label: 'Polestar 2 Long Range Single Motor' },
    { value: '181885', label: 'Polestar 2 Long Range Dual Motor' },
    { value: '184082', label: 'Tesla Model 3 Rear-Wheel-Drive' },
    { value: '184083', label: 'Tesla Model 3 All-Wheel-Drive (Long Range)' },
    { value: '184084', label: 'Tesla Model 3 Performance' },
    { value: '184843', label: 'Tesla Model Y Long-Range' },
    { value: '184085', label: 'Tesla Model Y Rear-Wheel-Drive' },
    { value: '184170', label: 'Volvo C40 Recharge Pure Electric'},
    { value: '184171', label: 'Volvo C40 Ultimate Twin Pure Electric'},
    { value: '182428', label: 'Volvo XC40 Recharge Electric Single Motor'},
    { value: '182429', label: 'Volvo XC40 Rechage Electric Twin Motor' },
];

export const polestarCars = [
    { value: '181887', label: 'Polestar 2 Standard Range Single Motor' },
    { value: '181886', label: 'Polestar 2 Long Range Single Motor' },
    { value: '181885', label: 'Polestar 2 Long Range Dual Motor' },
];


export const delay = (ms) =>
    new Promise((resolve) => setTimeout(resolve, ms));

    //icon
export const arrowButton = '/Images/arrow-right.svg';
export const backArrow = '/Images/back-arrow.svg';
export const dropdownIcon = '/Images/dropdown.svg';
export const dropdownIconColor = '/Images/chevron-down-color.svg'
export const cornerLeftIcon = '/Images/corner-up-left.svg';
export const x = '/Images/close.svg';
