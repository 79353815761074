import React from 'react';
import { useField } from 'formik';
import { DropdownWrappper } from './styled';

const CustomSelect = ({ name, options, setShowDropdown }) => {
  const [field ] = useField(name);

  const handleOptionClick = (optionValue) => {
    field.onChange({
      target: {
        name: field.name,
        value: optionValue,
      },
    });
    setShowDropdown(false);
  };


  return (
    <DropdownWrappper>
      <ul className="select-options" onMouseLeave={() => setShowDropdown(false)}>
        {options.map((option) => (
          <li className="list-options" key={option.value} onClick={() => handleOptionClick(option.value)}>
            {option.label}
          </li>
        ))}
      </ul>
    </DropdownWrappper>
  );
};

export default CustomSelect;
