import styled from 'styled-components';
import { theme } from '../../../../theme';

export const PackCardWrapper = styled.div`
    @keyframes flash {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.65;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes text-fade {
        0% {width : 50px;opacity:0.5;}
        100% {width : 140px;opacity:1;}
    }

    .transition-text {
        animation-name: text-fade;
        animation-duration: 300ms;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        overflow: hidden;
    }
    width: 25%;
    cursor: pointer;
    p {
        margin-bottom: 0;
        min-width: max-content;
    }
    .unselect-p{
        &:hover {
            .btn-choose{
                transition: transform 0.2s linear;
                transform: rotate(90deg);
            }
        }
    }
    h4 {
        img {
            margin-left: 0.5rem;
        }
    }
    position: relative;
    .check-orange-icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        z-index: 99;
    }
    .btn-choose {
        margin: 7px;
    }

    .pack-image, .unselect-pack-image {
        object-fit: cover;
        position: relative;
        transition: transform 0.4s;
    }
    
    ${theme.mediaQueriesMaxWidth.lg} {
        min-width: 250px;
    }
    &:hover {
        .unselect-pack-image{
            animation: flash 0.4s linear;
            transform: scale(1.1);
        }
    }
`;

export const LabelCard = styled.div`
    justify-content: space-between;
    margin: 1.5rem 0;
    height: 1.5rem;
    cursor: pointer;
    display: flex;
    h4 {
        display: flex;
        font-size: 20px;
    }
    .tooltip-container {
        width: 18px;
        margin-left: 10px;
        position: relative;
        .tooltip-text {
            visibility: hidden;
            position: absolute;
            font-size: 12px;
            width: 300px;
            background: #f8f8f8;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: 0px 4px 6px -2px #10182808;
            /* box-shadow: 0px 12px 16px -4px #10182814; */
            border-radius: 4px;
            padding: 12px;
            h5 {
                margin-bottom: 5px;
                font-size: 18px;
            }
            p{
                min-width: 100%;
            }
            a {
                text-decoration: underline;
                color: orange;
            }
            ::after {
                content: '';
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: #f8f8f8 transparent transparent transparent;
            }
            ${theme.mediaQueriesMaxWidth.md} {
                bottom: 75px;
            }
        }
        :hover {
            .tooltip-text {
                visibility: visible;
            }
            .tooltip-icon {
                fill: orange;
            }
        }
        ${theme.mediaQueriesMaxWidth.md} {
        position: static;
    }
    }
    .tooltip-icon {
        :hover {
            fill: orange;
            .tooltip-text {
                visibility: visible;
            }
        }
    }
`;

export const StandardCard = styled.div`
    min-width: 25%;
    max-width: 25%;
    padding: 1.5rem 2.25rem 0 1.5rem;
    outline: ${(props) =>
        props.isSelected ? 'solid 1px orange' : 'solid 1px gray'};
    position: relative;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine){
        :hover {
        outline: solid 1px orange;
    }
    }
    .check-orange-icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
    span {
        position: absolute;
        bottom: 45px;
        color: gray;
    }
    ${theme.mediaQueriesMaxWidth.lg} {
        min-width: 250px;
        max-width: 250px;
    }
`;
