import React, { useState } from 'react';
import { CompareNovatedLeaseWrapper, TableCarCard, ExtraTableStyled, PopupTableStyled } from './styled';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { dropdownIconColor, x } from '../../../../utils/helpers';

const ExtraTable = ({isPopup}) => {
    return (
        <ExtraTableStyled>
            <Row>
                <Col xs={12} style={{marginBottom:"12px"}}>
                    <p className={`title-table ${isPopup && "title-popup"}`}>Breakdown of running costs over 5 years</p>
                </Col>
            </Row>
            <Row
                    style={{
                        marginBottom: '6px',
                        color: "gray"
                    }}
                >
                    <Col xs={isPopup ? 0 : 2} md={true}></Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>Monthly</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>Annualy</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>Monthly</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>Annualy</p>
                    </Col>
            </Row>
            <Row
                >
                    <Col style={{color:"gray" , marginBottom: "-12px"}} xs={isPopup ? 12 : 2} md={true} >
                        <p>Charging</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>    
                        <p>$ 44.00</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 527.96</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 274.99</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 3,299.87</p>
                    </Col>
            </Row>
            <Row 
                >
                    <Col style={{color:"gray" , marginBottom: "-12px"}} xs={isPopup ? 12 : 2} md={true}>
                        <p>Registration</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 75.36</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 904.33</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 75.36</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 904.33</p>
                    </Col>
            </Row>
            <Row 
                >
                    <Col style={{color:"gray" , marginBottom: "-12px"}} xs={isPopup ? 12 : 2} md={true}>
                        <p>Service & Maintenance</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 11.01</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 132.13</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 71.50</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 858.00</p>
                    </Col>
            </Row>
            <Row 
                >
                    <Col style={{color:"gray" , marginBottom: "-12px"}} xs={isPopup ? 12 : 2} md={true}>
                        <p>Tyre Replacement</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 24.19</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 290.29</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 23.45</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 281.42</p>
                    </Col>
            </Row>
            <Row 
                >
                    <Col style={{color:"gray" , marginBottom: "-12px"}} xs={isPopup ? 12 : 2} md={true}>
                        <p>Roadside Assistance</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 6.51</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 78.08</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 6.51</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 78.08</p>
                    </Col>
            </Row>
            <Row 
                >
                    <Col style={{color:"gray" , marginBottom: "-12px"}} xs={isPopup ? 12 : 2} md={true}>
                        <p>Comprehensive Insurance</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 153.80</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 1,845.56</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 153.80</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 1,845.56</p>
                    </Col>
            </Row>
            <Row style={{marginBottom: '12px',
                        borderBottom: '1px solid #CECFD2' }}
                >
                    <Col style={{color:"gray",marginBottom: "-12px"}} xs={isPopup ? 12 : 2} md={true}>
                        <p>Total</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 314.86</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 3,778.35</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 605.61</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 7,267.26</p>
                    </Col>
            </Row>
            <Row style={{fontSize:"16px",fontWeight:600}}
                >
                    <Col style={{color:"gray", marginBottom: "-12px"}} xs={isPopup ? 12 : 2} md={true}>
                        <p>Total over term</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 18,891.73</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2}md={true}>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                        <p>$ 36,336.30</p>
                    </Col>
                    <Col xs={isPopup ? 3 : 2} md={true}>
                    </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <p className='text-assumptions' style={{fontSize:"12px !important"}}>Assumptions:
                    Roadside assistance & comprehensive insurance budgets are equal in both scenarios. 
                    Registration renewals are equal in both scenarios.
                    Fuel Cost & servicing for ICE is greater than EV. 
                    All figures are shown inclusive of GST.</p>
                </Col>
            </Row>
        </ExtraTableStyled>
    )
}

export const CompareNovatedLease = ({cars}) => {
    const [tabActive,setTabActive] = useState("Novated Lease Comparison")
    const [extraTable,setExtraTable] = useState(false)
    const [openDropdown,setOpenDropdown] = useState(false)
    return (
        <CompareNovatedLeaseWrapper>
            <Container className="no-pd-col">
                <Row className="title-tab">
                    <Col className="flex title no-pd space-between item-center">
                        <h3>Compare your savings on a novated lease</h3>
                    </Col>
                    <div className="flex compare-tab">
                        <p className={`table-tab ${tabActive === "Novated Lease Comparison"  && "active"}`} onClick={()=> setTabActive("Novated Lease Comparison")}>Novated Lease Comparison</p>
                        <p className={`table-tab ${tabActive === "Car Loan Comparison"  &&"active"}`} onClick={()=> setTabActive("Car Loan Comparison")}>Car Loan Comparison</p>
                    </div>
                    <div className='compare-tab-mobile' onClick={()=>setOpenDropdown(!openDropdown)}>
                        {/* <select onChange={(e)=> setTabActive(parseInt(e.target.value))}>
                            <option value={0}>Novated Lease Comparison</option>
                            <option value={1}> Car Loan Comparison</option>
                        </select> */}
                        <p>{tabActive}</p>
                        <img src="/Images/dropdown.svg" alt='dropdown-icon' />
                        {openDropdown && <div className='custom-dropdown'>
                            <div onClick={()=> {setTabActive("Novated Lease Comparison");setOpenDropdown(false)}}>Novated Lease Comparison</div>
                            <div onClick={()=> {setTabActive("Car Loan Comparison");setOpenDropdown(false)}}>Car Loan Comparison</div>
                        </div>}
                    </div>
                </Row>
                
                { tabActive === "Novated Lease Comparison" ? <div className='compare-table-1'>
                    <Row className='row-table' style={{ marginBottom: '40px' }}>
                    <Col xs={0} md={3} />
                    <Col xs={6} md={true}>
                        <div className="image-container">
                            <Image
                                className="image-car"
                                src="/Images/polestar-car.png"
                            />
                        </div>
                        <div>
                            <p>Electric Vehicle</p>
                            <h4 className='car-name'>Polestar 2 Standard Range Single Motor</h4>
                        </div>
                    </Col>
                    <Col xs={6} md={true} className="no-pd-col">
                        <div className="image-container">
                            <Image
                                className="image-car"
                                src="/Images/petrol-car.png"
                            />
                        </div>
                        <div>
                            <p>Petrol Vehicle</p>
                            <h4 className='car-name'>Comparable ICE Vehicle</h4>
                        </div>
                    </Col>
                </Row>
                <Row
                    className='row-table'
                    style={{
                        borderBottom: '1px solid #CECFD2',
                        marginBottom: '24px',
                    }}
                >
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>FBT method</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>Exempt Reportable</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>Statutory using ECM</p>
                    </Col>
                </Row>
                <Row className='row-table'>
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            Total driveway price
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 75,400.05</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 75,400.05</p>
                    </Col>
                </Row>
                <Row
                    className='row-table'
                    style={{
                        marginBottom: '24px',
                        borderBottom: '1px solid #CECFD2',
                    }}
                >
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            GST saving on purchase price
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 6,191.64</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 6,191.64</p>
                    </Col>
                </Row>
                <Row className='row-table'>
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            Running costs over 5 years
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 18,891.73</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <div className="flex space-between">
                            <p>$ 33,942.48</p>
                            <div
                                className="right-dropdown"
                                style={{
                                    gap: '8px',
                                    alignItems: 'baseline',
                                    cursor: 'pointer',
                                    
                                }}
                                onClick={()=> setExtraTable(extraTable === "dropdown" ? false : "dropdown")}
                            >
                                <p style={{fontSize:"14px"}}>Cost breakdown</p>
                                <Image
                                    alt="icon"
                                    src={dropdownIconColor}
                                    className="icon-button"
                                    style={{marginBottom:"18px",alignSelf:"center",rotate:extraTable&& "-180deg"}}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="down-dropdown">
                    <Col>
                    <div
                        className="flex"
                        style={{
                            gap: '8px',
                            alignItems: 'baseline',
                            cursor: 'pointer',
                        }}
                        onClick={()=> setExtraTable(extraTable === "popup" ? false : "popup")}
                    >
                        <p style={{fontSize:"14px"}}>Cost breakdown</p>
                        <Image
                            alt="icon"
                            src={dropdownIconColor}
                            className="icon-button"
                            style={{marginBottom:"18px",alignSelf:"center",rotate:extraTable&& "-180deg"}}
                        />
                    </div>
                    </Col>
                </Row>
                <Row className={`extra-table-animated ${extraTable === "dropdown" && "extra-table-height"}`} >
                    <Col xs={12} style={{margin:"12px 0"}}>
                        <ExtraTable />
                    </Col>
                </Row>
                <Row className='row-table'>
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            Finance costs over 5 years
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 84,055.80</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 84,055.80</p>
                    </Col>
                </Row>
                <Row className='row-table'>
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                        Income tax savings over 5 years
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>- $ 32,500.00</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>- $ 15,600.00</p>
                    </Col>
                </Row>
                <Row
                    className='row-table'
                    style={{
                        marginBottom: '24px',
                        borderBottom: '1px solid #CECFD2',
                    }}
                >
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            Residual value
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 21,415.15</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 21,415.15</p>
                    </Col>
                </Row>
                <Row className='row-table' style={{ fontSize: '20px' }}>
                    <Col xs={0} md={3}>
                        <p  className='total-cost'>
                            Total cost over life
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 91,862.68</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 123,813.43</p>
                    </Col>
                </Row>
                <Row
                    className='row-table'
                    style={{
                        marginBottom: '24px',
                        fontSize: '14px',
                        color: '#94969C',
                    }}
                >
                    <Col xs={0} md={3}>
                        <p>Total savings</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 31,950.75</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <p className='text-assumptions' style={{fontSize:"12px !important"}}>
                        Assumptions:<br/>60 month lease travelling 15,00kms per annum with a gross annual salary of $90,000
                        Eligible EV is salary packaged on a novated lease using FBT Exemption 
                        ICE Vehicle is salary packaged on a novated lease using the Statutory method of offsetting FBT.
                        </p>
                    </Col>
                </Row>
                </div> 
                :
                <div className='compare-table-2'>
                <Row style={{ fontSize: '20px' }} className='row-table'>
                    <Col xs={0} md={12}>
                      <TableCarCard>
                            <Image src="/Images/polestar-car.png" />
                            <div className='card-car-detail'>
                                <p>Electric Vehicle</p>
                                <p className='car-name'>Polestar 2 Standard</p>
                                <div className='detail-box'>
                                    <p>Range : 505 - 546km (WLTP)</p>
                                    <p>0-100km/h : 6.4 sec</p>
                                </div>
                            </div>
                      </TableCarCard>
                    </Col>
                </Row>
                <Row className='row-table' style={{ fontSize: '24px', fontWeight:600, marginTop:"40px",marginBottom:"20px" }}>
                    <Col xs={0} md={3}>
                    </Col>
                    <Col  xs={6} md={true}>
                        <p>Novated Lease</p>
                    </Col>
                    <Col  xs={6} md={true}>
                        <p>Car Loan</p>
                    </Col>
                </Row>
                <Row className='row-table'>
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            Total driveway price
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 75,400.05</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 75,400.05</p>
                    </Col>
                </Row>
                <Row
                    className='row-table'
                    style={{
                        marginBottom: '24px',
                        borderBottom: '1px solid #CECFD2',
                    }}
                >
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            GST saving on purchase price
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 6,191.64</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p></p>
                    </Col>
                </Row>
                <Row className='row-table'>
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            Running costs over 5 years
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 18,892.00</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <div className="flex space-between">
                            <p>$ 18,892.00</p>
                        </div>
                    </Col>
                </Row>
                <Row className='row-table'>
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            Finance costs over 5 years
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 84,055.80</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 94,080.60</p>
                    </Col>
                </Row>
                <Row className='row-table'>
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                        Income tax savings over 5 years
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>- $ 32,500.00</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p></p>
                    </Col>
                </Row>
                <Row
                    className='row-table'
                    style={{
                        marginBottom: '24px',
                        borderBottom: '1px solid #CECFD2',
                    }}
                >
                    <Col xs={0} md={3}>
                        <p style={{ color: '#666666' }}>
                            Residual value
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 21,415.15</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p></p>
                    </Col>
                </Row>
                <Row className='row-table' style={{ fontSize: '20px' }}>
                    <Col xs={0} md={3}>
                        <p className='total-cost'>
                            Total cost over life
                        </p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 91,862.95</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 112,972.60</p>
                    </Col>
                </Row>  
                <Row
                    className='row-table'
                    style={{
                        marginBottom: '24px',
                        fontSize: '14px',
                        color: '#94969C',
                    }}
                >
                    <Col xs={0} md={3}>
                        <p>Total savings</p>
                    </Col>
                    <Col xs={6} md={true}>
                        <p>$ 21,109.65</p>
                    </Col>
                </Row>
                
                </div> 
                }
            </Container>
            {extraTable === "popup" && 
                <PopupTableStyled>
                    <Image 
                        alt="icon"
                        src={x}
                        className="close-btn"
                        onClick={()=>setExtraTable(false)} />
                    <ExtraTable isPopup />
                </PopupTableStyled>}
        </CompareNovatedLeaseWrapper>
    );
};
