import React, { useState } from 'react';
import {
    MessageFormContainer,
    QuoteContainer,
    FormContainer,
    InputStyled,
    PolicyContainer,
} from './styled';
import Select, { components } from 'react-select';
import { Formik, Form } from 'formik';
import { sendQuoteEmail } from '../../../../actions/VehicleActions';
import {
    delay,
    stateOptionsFull,
    timelineOptions,
    evOptions,
} from '../../../../utils/helpers';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Row, Col, Container } from 'react-bootstrap';
import Checkbox from '../Checkbox/Checkbox';
import Button from '../Button/Button';
import { arrowButton, dropdownIcon } from '../../../../utils/helpers';
import { useField } from 'formik';
import { Error } from '../Error';

const Input = ({ label, width, handleChange, name }) => {
    const meta = useField(name)[1];
    return (
        <>
            <InputStyled>
                <label>{label}</label>
                <input type="text" onChange={handleChange} name={name} />
                <Error meta={meta} />
            </InputStyled>
        </>
    );
};

const novatedOptions = [
    {
        label: 'Yes',
        value: 'Yes',
    },
    {
        label: 'No',
        value: 'No',
    },
];

const InputSelect = ({ label, width, option, placeHolder,handleChange, value }) => {
    const style = {
        control: (base) => ({
            ...base,
            border: 0,
            fontFamily: 'inherit',
            boxShadow: 'none',
            background: '#F3F4F4',
            paddingLeft: '10px',
        }),
    };
    const DropdownIndicator = ({ ...props }) => {
        return (
            <components.DropdownIndicator {...props}>
                <img src={dropdownIcon} alt="icon" />
            </components.DropdownIndicator>
        );
    };
    return (
        <InputStyled>
            <label>{label}</label>
            <Select
                options={option}
                className="react-select-container"
                classNamePrefix="react-select"
                styles={style}
                placeholder={placeHolder}
                components={{ DropdownIndicator }}
                onChange={handleChange}
                value={value}
            />
        </InputStyled>
    );
};

export const PolestarQuoteContainer = ({
    makesOptions,
    modelOptions,
    bodyTypeOptions,
    variantOptions,
    show,
    showQuote,
    setShowQuote,
    state,
    quote,
    ...props
}) => {
    const data = state.leaseData;
    const cost_per_fortnight = state.cost_per_fortnight;
    const saving_per_year = state.saving_per_year;
    const fortnight_running_cost = null;
    const fortnight_car_cost = null;
    const quote_type = null;
    const car_type =state.car_type;
    const isEv = true;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedTimeline, setSelectedTimeline] = useState(null);
    const [selectedNovate, setSelectedNovate] = useState(null);


    const initialValues = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '+61',
        employer: '',
        employerwebsite: 'www.',
        state: '',
        timeline: '',
        note: '',
        consent: false,
        novated:'no'
    };
    const validationSchema = Yup.object({
        firstname: Yup.string().required('This field is required'),
        lastname: Yup.string().required('This field is required'),
        email: Yup.string().email().required('This field is required'),
        phone: Yup.number()
            .test('is-required-and-valid',
            'This field is required', (v) => v?.toString().length > 3)
            .required('This field is required')
            .typeError(`Phone number must be a 'number' type`)
            ,
        employer: Yup.string().required('This field is required'),
        consent: Yup.boolean()
            .oneOf([true], 'You must consent to proceed')
            .required('You must consent to proceed'),
    });




    const handleSubmit = async (values, { resetForm }) => {
        try {
            if (JSON.stringify(data) !== JSON.stringify({})) {
                var make = getMake(data, makesOptions);
                var variant = getVariant(
                    data,
                    isEv ? evOptions : variantOptions
                );
                var vehicleModel = getModel(data, modelOptions);
                var bodyType = getBody(data, bodyTypeOptions);
            }

            let packsName = data.packs.map(a => a.pack);
            let packs = packsName.join(", ")
            var referrer = '';
            var car_photo = '';

            var params = { '': '' };
            var state = data.state ?? values.state;
            const vehicle_make = quote.make;
            const vehicle_model = quote.model;
            const model_year = quote.model_year;
            const body_type = quote.body;

            sendQuoteEmail(
                values,
                make,
                variant,
                vehicleModel,
                bodyType,
                packs,
                data.salary,
                state,
                data.travelDistance,
                data.year,
                data.leaseTerm,
                referrer,
                car_photo,
                cost_per_fortnight,
                saving_per_year,
                fortnight_running_cost,
                fortnight_car_cost,
                params,
                quote_type,
                car_type,
                isEv ,
                vehicle_make,
                vehicle_model,
                model_year,
                body_type,
            );
            return delay(2000).then(() => {
                toast.success('Email has been sent.');
                setIsSubmitted(true);
                resetForm();
                setSelectedNovate(null);
                setSelectedState(null);
                setSelectedTimeline(null);
            });
        } catch (e) {
            toast.error('Email is failed to send.');
            resetForm();
            setSelectedNovate(null);
            setSelectedState(null);
            setSelectedTimeline(null);
        }
        resetForm();
        setSelectedNovate(null);
        setSelectedState(null);
        setSelectedTimeline(null);
    };

    const getMake = (data, makesOptions) => {
        var makelabel = makesOptions.find(
            (make) => make.value === data.vehicle_make
        );
        return makelabel?.label;
    };
    const getVariant = (data, variantOptions) => {
        var variantlabel = variantOptions.find(
            (variant) => variant.value === data.variants
        );
        return variantlabel?.label;
    };
    const getModel = (data, modelOptions) => {
        var modellabel = modelOptions.find(
            (model) => model.value === data.vehicle_model
        );
        return modellabel?.label;
    };
    const getBody = (data, bodyTypeOptions) => {
        var bodylabel = bodyTypeOptions.find(
            (bodyType) => bodyType.value === data.body_type
        );
        return bodylabel?.label;
    };

    return (
        <MessageFormContainer show={show}>
            <Container className="container-form">
                <Row>
                    <Col xs={12} xl={4} className="no-pd-col">
                        <QuoteContainer>
                            <h3>Get a full quote</h3>
                            <p>
                                Please answer a few more questions and one
                                of our novated leasing specialists will
                                contact you to assist with a detailed
                                quote.
                            </p>
                        </QuoteContainer>
                    </Col>
                    <Col
                        className="no-pd-col"
                        style={{
                            border: 'solid 1px #CCCCCC',
                        }}
                    >
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validationSchema={validationSchema}
                        >
                            {({
                                values,
                                isSubmitting,
                                handleChange,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form>
                                        {isSubmitted ? (
                                            <FormContainer>
                                                <div className="quote-success">
                                                    <h2>Thank You</h2>
                                                    <p>
                                                    Our novated leasing partner agency, <a href="https://www.easifleet.com.au/"
                                                                target="_blank"
                                                                rel="noopener noreferrer">Easi</a> will be in touch with a quote shortly.
                                                    </p>

                                                    <Button
                                                        text="Back to Calculator"
                                                        type="button"
                                                        buttonType="secondary"
                                                        onClick={() => {
                                                            setShowQuote(
                                                                false
                                                            );
                                                            setIsSubmitted(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </FormContainer>
                                        ) : (
                                            <>
                                                <FormContainer>
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <Input
                                                                label="First name"
                                                                name="firstname"
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <Input
                                                                label="Last name"
                                                                name="lastname"
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <Input
                                                                label="Email Address"
                                                                name="email"
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <Input
                                                                label="Phone Number"
                                                                name="phone"
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <Input
                                                                label="Employer name"
                                                                name="employer"
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <Input
                                                                label="Employer website"
                                                                name="employerwebsite"
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <InputSelect
                                                                label="State"
                                                                option={
                                                                    stateOptionsFull
                                                                }
                                                                placeHolder="Select State"
                                                                name="state"
                                                                value={selectedState}
                                                                handleChange={(selectedState) => {
                                                                    setSelectedState(selectedState);
                                                                    setFieldValue('state', selectedState.value);
                                                                }
                                                                }
                                                            />
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <InputSelect
                                                                label="Will buy"
                                                                option={
                                                                    timelineOptions
                                                                }
                                                                name="timeline"
                                                                value={selectedTimeline}
                                                                handleChange={(selectedTimeline) => {
                                                                    setSelectedTimeline(selectedTimeline);
                                                                    setFieldValue('timeline', selectedTimeline.value);
                                                                }
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <InputSelect
                                                                label="Have you had a novated lease before"
                                                                option={
                                                                    novatedOptions
                                                                }
                                                                placeHolder="Choose"
                                                                name='novated'
                                                                value={selectedNovate}
                                                                handleChange={(selectedNovate) => {
                                                                    setSelectedNovate(selectedNovate);
                                                                    setFieldValue('novated', selectedNovate.value);
                                                                }
                                                                }
                                                            />
                                                        </Col>
                                                        <Col
                                                            xs={12}
                                                            xl={6}
                                                        >
                                                            <Input
                                                                label="Notes"
                                                                name="note"
                                                                handleChange={
                                                                    handleChange
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </FormContainer>
                                                <PolicyContainer>
                                                    <p>
                                                        By submitting, the
                                                        data provided will
                                                        be used to perform
                                                        your request
                                                        according to the{' '}
                                                        <span>
                                                            <a
                                                                href="https://www.polestar.com/au/legal/privacy/"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Information notice
                                                            </a>
                                                        </span>
                                                    </p>

                                                    <p>
                                                        Polestar can
                                                        contact you via
                                                        email about the
                                                        related products
                                                        and services from
                                                        Polestar and
                                                        Polestar's business
                                                        partners.
                                                    </p>
                                                    <Checkbox
                                                        type="checkbox"
                                                        label="I consent to Easifleet processing my data in order to offer a personalised marketing experience through Easifleet and other channels. This includes customised ads based on my profile and the development of ads for specific audience segments"
                                                        name="consent"
                                                        checked={
                                                            values.consent
                                                        }
                                                        onChange={() => {
                                                            setFieldValue(
                                                                'consent',
                                                                !values.consent
                                                            );
                                                        }}
                                                    />
                                                    <Button
                                                        type="submit"
                                                        style={{
                                                            margin: '15px 0px',
                                                        }}
                                                        disabled={
                                                            isSubmitting
                                                        }
                                                        text={
                                                            isSubmitting
                                                                ? 'Submitting...'
                                                                : 'Request Quote'
                                                        }
                                                        icon={arrowButton}
                                                    />
                                                </PolicyContainer>
                                            </>
                                        )}
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        </MessageFormContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        makesOptions: state.vehicle.make_options,
        modelOptions: state.vehicle.model_options,
        bodyTypeOptions: state.vehicle.body_type_options,
        variantOptions: state.vehicle.variant_options,
        quote: state.vehicle.quote
    };
};

export const Quote = connect(mapStateToProps)(PolestarQuoteContainer);
