import styled from 'styled-components';
import { theme } from '../../../../theme';

export const CompareNovatedLeaseWrapper = styled.div`
    padding: 32px 0;
    text-align: left;
    ${theme.mediaQueriesMaxWidth.md} {
        padding: 16px 0 56px 0;
        }
    p{
        font-size: 18px;
    }
    h2 {
        font-size: 44px;
    }

    h3 {
        font-size: 30px;
        ${theme.mediaQueriesMaxWidth.md} {
            font-size: 26px;
            margin-left: 16px;
        }
    }

    h4 {
        font-size: 24px;
    }
    .no-pd-col {
        padding-right: 0px;
        padding-left: 0px;
        p{
            font-size: 18px;
        }
        ${theme.mediaQueriesMaxWidth.md} {
            padding-right: 15px;
            padding-left: 15px;
        }
        
        ${theme.mediaQueriesMaxWidth.lg} {
            p{
                font-size: 16px;
            }
        }
    }

    .title {
        max-width: 400px;
        text-align: left;
        ${theme.mediaQueriesMaxWidth.lg} {
            align-self:baseline ;
        }
    }

    .compare-tab {
        gap: 32px;
        ${theme.mediaQueriesMaxWidth.lg} {
            align-self: end;
        }
        ${theme.mediaQueriesMaxWidth.md} {
            display: none;
        }
    }

    .compare-tab-mobile {
        display: none;
        width: 95%;
        outline: solid 1px #E1E1E1;
        margin: 0 auto;
        padding : 16px;
        position: relative;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        p{
        margin : 0;
        }
        .custom-dropdown{
            position: absolute;
            bottom: -120px;
            width: 100%;
            left: 0;
            background: white;
            z-index: 99;
            outline: solid 1px #E1E1E1;
            div{
                padding :16px;
                cursor: pointer;
                :hover{
                    background: #F0F0F080;
                }
            }
        }
        ${theme.mediaQueriesMaxWidth.md} {
            display: flex;
        }
    }

    .title-tab {
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 56px;
        ${theme.mediaQueriesMaxWidth.lg} {
            flex-direction: column;
        }
    }

    .image-container {
        max-width: 420px;
        height: 289px;
        margin-bottom: 40px;
        ${theme.mediaQueriesMaxWidth.lg} {
            height: 172px;
        }
        ${theme.mediaQueriesMaxWidth.sm} {
            height: clamp(112px, 30vw, 160px);
        }
    }
    .car-name{
        ${theme.mediaQueriesMaxWidth.md} {
            font-size: 18px;  
        }
    }

    .image-car {
        width: 100%;
        height: 100%;
        object-fit: contain;
        ${theme.mediaQueriesMaxWidth.lg} {
            object-fit: cover;
        }
    }
    .table-tab{
        padding-bottom: 8px;
        opacity: 0.75;
        cursor: pointer;
    }
    .active{
        opacity: 1;
        font-weight: 600;
        border-bottom : solid 1px #FF7602;
    }
    .extra-table-animated{
        overflow: hidden;
        height: 0px;
        opacity: 0;
        transition: all 0.5s ease-in-out;
    }
    .extra-table-height{
        height: 620px;
        opacity: 1;
        ${theme.mediaQueriesMaxWidth.table} {
            height: 700px;
        }

    }
    .icon-button{
        transition: all 0.5s ease-in-out;
    }
    @keyframes compare-table-transition-1 {
        from{
            opacity: 0;
        }to{
            opacity: 1;
        }
    }
    @keyframes compare-table-transition-2 {
        from{
            opacity: 0;
        }to{
            opacity: 1;
        }
    }
    .row-table{
        gap: "18px";
        ${theme.mediaQueriesMaxWidth.md} {
            gap: 0;
        }
    }
    .compare-table-1{
        opacity: 0;
        animation: compare-table-transition-1 500ms ease-in-out forwards;
    }
    .compare-table-2{
        opacity: 0;
        animation: compare-table-transition-2 500ms ease-in-out forwards;
    }
    .text-assumptions{
        font-size: 12px !important;
        color: #666666;
        margin: 0;
        margin-top: 12px;
        width: 50%;
        ${theme.mediaQueriesMaxWidth.md} {
            gap: 0;
            margin-top: 24px;
            width: 100%;
        }
    }

    .right-dropdown{
        display: flex;
        ${theme.mediaQueriesMaxWidth.md} {
            display: none;
        }
    }
    .down-dropdown{
        display: none;
        ${theme.mediaQueriesMaxWidth.md} {
            display: block;
        }
    }
    .total-cost{
        color : #FF7602;
        font-size: 20px !important;
    }
`;

export const TableCarCard = styled.div`
    display: flex;
    padding: 16px;
    background-color: #ECECE7;
    gap: 32px;
    align-items: center;
    ${theme.mediaQueriesMaxWidth.md} {
            display: block;
        }
    img{
        width: 200px;
        height: auto;
        object-fit: contain;
    }
    .card-car-detail{
        width: 100%;
        margin-top: 8px;
        font-size: 16px;
        .car-name{
            font-size: 22px;
            font-weight: 600;
        }
        .detail-box{
            display: flex;
            gap: 32px;
            color: #61646C;
            ${theme.mediaQueriesMaxWidth.md} {
            flex-direction: column;
            gap: 0;
        }
        }
    }

`

export const ExtraTableStyled = styled.div`
    padding: 40px;
    background-color: #ECECE7;
    font-size: 14px;
    p{
        font-size: 16px !important;
    }
    ${theme.mediaQueriesMaxWidth.lg} {
            p{
                font-size: 14px !important;
            }
            .col-md{
                padding:0;
            }
        }
    ${theme.mediaQueriesMaxWidth.md} {
        p{
            font-size: 16px !important;
        }
        .col-12{
            padding: 0;
        }
    }
    ${theme.mediaQueriesMaxWidth.sm} {
        padding: 28px;
    }
    .title-table{
        font-size: 20px;
        font-weight: 600;
        
    }
    .title-popup{
        font-size: 26px !important;
        margin-left: -12px;
        width: 75%;
        ${theme.mediaQueriesMaxWidth.md} {
        margin-left: 0px;
        font-size: 22px !important;
    }
    }
`

export const PopupTableStyled = styled.div`
    position: fixed;
    top: 0;
    height: 100vh;
    background: #ECECE7;
    overflow-y: auto;
    z-index: 10;
    .close-btn{
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
        z-index: 99;
    }

`

