import React, { useState } from 'react';
import { RangeSliderWrappper } from './RangeSliderStyled';
import { theme } from '../../../../theme';
import { Range, getTrackBackground } from 'react-range';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { IS_LOADING } from '../../../../actions/actionTypes';
import { connect } from 'react-redux';

export const RangeSliderContainer = ({
    headtext,
    current,
    isLoading,
    min,
    max,
    step,
    after = '',
    before = '',
    name,
    customHandle,
    handleFinalChange,
    options,
    overMax,
    className,
    ...props
}) => {
    const [values, setvalues] = useState([current]);
    const { setFieldValue } = useFormikContext();
    const dispatch = useDispatch();
    const valuesOnChange = (values) => {
        if (!isLoading) dispatch({ type: IS_LOADING, payload: true });
        setvalues(values);
        setFieldValue(
            name,
            options ? options[values[0]].value : values[0] ?? ''
        );
        if (customHandle) {
            customHandle(values[0]);
        }
    };
    const onFinalChange = (values) => {
        dispatch({ type: IS_LOADING, payload: false });
        if (handleFinalChange) {
            handleFinalChange(values[0]);
        }
    };
    return (
        <RangeSliderWrappper className={className}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <p>{headtext}</p>
                <h3>
                    {before +
                        '' +
                        (options
                            ? options[values[0]].label.toLocaleString(
                                  'en-US'
                              )
                            : values[0].toLocaleString('en-US')) +
                        ' ' +
                        after}{' '}
                    {overMax && current === max ? ' Plus' : ''}
                </h3>
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    margin: '',
                }}
            >
                <Range
                    disabled={props.disabled ? true : false}
                    name={name}
                    values={values}
                    step={step}
                    min={min}
                    max={max}
                    onChange={valuesOnChange}
                    onFinalChange={onFinalChange}
                    renderTrack={({ props, children }) => (
                        <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                                ...props.style,
                                height: '36px',
                                display: 'flex',
                                width: '100%',
                            }}
                        >
                            <div
                                ref={props.ref}
                                style={{
                                    height: '2px',
                                    width: '100%',
                                    background: getTrackBackground({
                                        values: values,
                                        colors: [
                                            theme.colors.black,
                                            '#ccc',
                                        ],
                                        min: min,
                                        max: max,
                                    }),
                                    alignSelf: 'center',
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                    renderThumb={({ props, isDragged }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '10px',
                                width: '10px',
                                borderRadius: '35px',
                                backgroundColor: 'black',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 2px 6px #AAA',
                                outline: 'none',
                                cursor: 'pointer',
                            }}
                        ></div>
                    )}
                />
            </div>
        </RangeSliderWrappper>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.vehicle.isLoading,
    };
};
export const RangeSlider = connect(mapStateToProps)(RangeSliderContainer);
