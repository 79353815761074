import styled from 'styled-components';
import { theme } from '../../../../theme';

export const HeaderContainer = styled.div`
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    .header-container {
        display: flex;
        justify-content: space-between;
        padding-left: 0px;
        padding-right: 0px;
        ${theme.mediaQueriesMaxWidth.md} {
            padding-right: 15px;
            padding-left: 15px;
            flex-direction: column;
            align-items: baseline;
            margin-top: 24px;
        }
        button{
            ${theme.mediaQueriesMaxWidth.md} {
                border: none;
                padding: 12px 0px;
            }
        }
    }
`;
