import styled from 'styled-components';
import { theme } from '../../../../theme';

export const NovatedLeaseContainer = styled.div`
    padding: 32px 0;
    ${theme.mediaQueriesMaxWidth.md} {
        padding: 0; 
    }
    h2 {
        font-size: 44px;
    }

    h3 {
        font-size: 30px;
    }

    h4 {
        font-size: 22px;
        ${theme.mediaQueriesMaxWidth.md} {
            font-size: 18px;
            margin-top: 18px;
        }
    }
    .no-pd-col {
        padding-right: 0px;
        padding-left: 0px;
        ${theme.mediaQueriesMaxWidth.md} {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .title {
        max-width: 600px;
        text-align: left;
        ${theme.mediaQueriesMaxWidth.md} {
            font-size: 32px;
        }
    }

    .saving-title-container {
        padding: 32px 0 0 0;
        text-align: left;
        margin-bottom: 28px;
        
    }
    .saving-container {
        display: flex;
        gap: 8px;
        justify-content: space-between;
        ${theme.mediaQueriesMaxWidth.md} {
            display: block;
        }
    }
    .saving-title-container h3 {
        max-width: 550px;
        ${theme.mediaQueriesMaxWidth.md} {
            font-size: 26px;
        }
    }
`;

export const OverallSavingContainer = styled.div`
    text-align: left;
    max-width: 30%;
    h1 {
        font-size: 80px;
        color: #ff7602;
        margin-bottom: 32px;
        ${theme.mediaQueriesMaxWidth.md} {
            margin-bottom: 24px;
        }
    }

    p {
        font-size: 18px;
    }
    ${theme.mediaQueriesMaxWidth.md} {
        max-width: 100%;
        h1 {
            font-size: 42px
        }
        p{
            font-size: 16px;
        }
    }
`;
