import styled from 'styled-components';
import { theme } from '../../theme';
import '../../styles/App.scss'


export const PolestarCalcStyled = styled.div`
     font-family: Arial, Helvetica, sans-serif;
    
    .flex {
        display: flex;
    }

    .space-between {
        justify-content:space-between;
    }

    .item-center {
        align-items: center;
    }
    .no-pd {
        padding-right: 0px;
        padding-left: 0px;
        margin-bottom: 24px;
        ${theme.mediaQueriesMaxWidth.md} {
            /* margin-bottom: 0px; */
        }
    }
    .img-icon {
        margin: 0 10px;
    }

    .state-row {
        padding-bottom: 70px;
    }

    .state-label {
        margin-bottom: 24px;
        line-height: 1.2rem;
    }
    .pack-container {
        ${theme.mediaQueriesMaxWidth.lg} {
            padding-right: 0px;
        }
        .slider-container{
        ${theme.mediaQueriesMaxWidth.lg} {
            padding: 0;
            margin-top: 40px;
            padding-right: 15px;
        }
    }
    @keyframes pack-transition {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
    }
    .col-pack {
        display: flex;
        opacity: 0;
        gap: 2%;
        padding: 1px 15px 1px 1px;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        ${theme.mediaQueriesMaxWidth.lg} {
            overflow-x: auto;
            gap: 15px;
            padding-left: 15px;
        }
        animation: pack-transition 500ms ease-in-out forwards;
    }

        .transition-width {
        transition: all 0.3s ease-in-out; 
        }

    .pack-row {
        overflow: 'hidden';
        justify-content: 'space-between';
        margin-bottom: '90px';
        ${theme.mediaQueriesMaxWidth.lg} {
            overflow-x: auto;
        }
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .state-cont {
        position: relative;
        display: flex;
        :hover {
            .img-icon {
                animation: arrowAnimation 200ms ease-in-out 1,
                    arrowAnimation2 200ms ease-in-out 200ms forwards;
            }
        }
        @keyframes arrowAnimation {
            0% {
                left: 15%;
            }
            100% {
                opacity: 0.5;
                left: 100%;
            }
        }
        @keyframes arrowAnimation2 {
            0% {
                left: -100%;
                opacity: 0.5;
            }
            100% {
                left: 15%;
                opacity: 1;
            }
        }
    }
    .state-change {
        overflow: hidden;
        width: 25px;
        display: flex;
        margin-left: 5px;
        .img-icon {
            position: relative;
            left: 15%;
            margin: 0;
            width: 20px;
        }
    }

    .slider-container {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }
    .sub-title-heading{
        h4{
            font-size: 28px;
        }
        ${theme.mediaQueriesMaxWidth.md} {
            h4{
                font-size:20px;
            }
        }
    }

    .source-container {
        gap: 8px;
        text-align: center;
        vertical-align: middle;
        p {
            font-size: 14px;
        }

        img {
            height: 14px;
            width: auto;
        }
        ${theme.mediaQueriesMaxWidth.md} {
            display: none;
        }
    }
`;
