import styled from 'styled-components';

export const ButtonWrapper = styled.button`
    background-color: ${(props) =>
        props.buttonType === 'secondary' ? '#fff' : '#000'};
    color: ${(props) =>
        props.buttonType === 'secondary' ? '#000' : '#fff'};
    border: ${(props) =>
        props.buttonType === 'secondary' ? 'solid 1px' : 'none'};
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    margin: 15px;
    &:hover {
        background-color: ${(props) =>
            props.buttonType === 'secondary' ? 'none' : '#383838'};
    }

    .icon-button {
        margin: 0 0 0 10px;
    }

    ${(props) =>
        props.disabled &&
        `
    background-color: #d7d7d7;
    cursor: not-allowed;
    border:none;
    &:hover, &:focus {
        background-color: #d7d7d7;
    }

    span {
        font-weight: 400;
    }
`}
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;
