import './utils/helpers';
import React from 'react';
import { Router } from '@reach/router';

import { PolestarCalculator } from './pages/PolestarCalculator/PolestarCalculator';

import  "./styles/font.css";
import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { theme } from './theme';
import { ThemeProvider } from 'styled-components';
import './styles/App.scss';
import { Header } from './pages/PolestarCalculator/components/Header';
import Footer from './pages/PolestarCalculator/components/Footer/Footer';



function App() {
    return (
        
        <>
        <div className="App">
        <ThemeProvider theme={theme}>
            <Header />
            <Router>
                <PolestarCalculator path="/" />
            </Router>
            <Footer />
        </ThemeProvider>

        </div>
        </>
   

    );
}

export default App;
