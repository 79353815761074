import React from 'react';
import { HeaderContainer } from './styled';
import { Container, Image } from 'react-bootstrap';
import Button from '../Button/Button';
import { cornerLeftIcon } from '../../../../utils/helpers';

const Header = () => {
    return (
        <HeaderContainer>
            <Container className="header-container">
                <img src="/Images/logo-easi.svg" alt="logo" />
                <a href='https://www.easifleet.com.au/polestar/'>
                <Button
                    text={'Return to easifleet.com.au'}
                    buttonType={'secondary'}
                    style={{
                        marginLeft: '0px',
                    }}
                    textStyle={{ verticalAlign: 'middle' }}
                >
                    <Image
                        alt="icon"
                        src={cornerLeftIcon}
                        className="icon-button"
                        style={{ margin: '0 8px 0 0' }}
                    />
                </Button>
                </a>
            </Container>
        </HeaderContainer>
    );
};

export default Header;
