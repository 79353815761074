import styled from 'styled-components';
import { theme } from '../../../../theme';

export const PolestarCalcStyled = styled.div``;

export const CardWrapper = styled.div`
    position: relative;
    h3 {
        font-weight: 400;
        line-height: 1;
        font-size: 1.5rem;
    }

    .container-display {
        display: flex;
        position: relative;
        margin: 38px 0px 90px;
        ${theme.mediaQueriesMaxWidth.md} {
            margin: 0px 0px 0px;
        }
    }

    .container-display-selected {
        display: flex;
        position: relative;
        margin: 38px 0px 90px;
        ${theme.mediaQueriesMaxWidth.md} {
            margin: 0px 0 0;
            right: 0;
        }
    }

    .row-package {
        padding-right: 0px;
        padding-left: 0px;
        cursor: pointer;
        &:hover {
            .img-display{
                animation: flash 0.4s linear;
                transform: scale(1.1);
            }
            
        }
    }

    @keyframes text-fade {
        0% {width : 50px;opacity:0.5;}
        100% {width : 140px;opacity:1;}
    }

    .transition-text {
        animation-name: text-fade;
        animation-duration: 300ms;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        overflow: hidden;
    }
    .select-text{
        &:hover {
            .btn-choose{
                transition: transform 0.2s linear;
                transform: rotate(90deg);
            }
            
        }
    }

    .btn-choose {
        margin: 7px;
        
    }
    

    .container-img {
        max-width: 390px;
        min-width: 320px;
        overflow: hidden;
        padding-right: 0px;
        padding-left: 0px;
        ${theme.mediaQueriesMaxWidth.sm} {
            width: 300px;
        }
    }

    .img-display {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: relative;
        transition: transform 0.3s;
        
    }

    @keyframes flash {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.65;
        }
        100% {
            opacity: 1;
        }
    }

    .check-orange-icon {
        position: absolute;
        z-index: 9;
        right: 1em;
        top: 1em;
    }

    .img-display-hover {
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: transform 0.1s linear;
        transform: scale(1.1);
        background: rgba(255, 255, 255, 0.5);
        animation: flash 0.4s linear;
    }

    .label-display {
        margin: 32px 0px;
    }

    

    .container-card-slider {
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        gap: 15px;
        ${theme.mediaQueriesMaxWidth.md} {
            max-width: 100%;
            overflow: auto;
            overflow-x: auto;
            scrollbar-width: thin;
            scrollbar-color: transparent transparent;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-thumb {
                background: transparent;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            padding: 5px;
            padding-right: 15px;
        }
    }

    ${theme.mediaQueriesMaxWidth.md} {
        h3 {
            font-size: 1.375rem;
        }

        p {
            font-size: 1rem;
        }
    }
`;
