import React from 'react';
import { useField } from 'formik';
import { Error } from '../Error';

import {
    CheckboxLabel,
    CheckboxContainer,
    CustomCheckbox,
    HiddenCheckbox,
} from './styled';

const Checkbox = ({ label, name, checked, onChange }) => {
    const meta = useField(name)[1];
    return (
        <div style={{position:'relative', paddingBottom: '20px'}}>
            <CheckboxContainer>
                <CustomCheckbox checked={checked} onClick={onChange} />
                <HiddenCheckbox
                    checked={checked}
                    onChange={onChange}
                    name={name}
                />
                <CheckboxLabel>{label}</CheckboxLabel>
            </CheckboxContainer>
            <Error meta={meta} />
        </div>
    );
};

export default Checkbox;
