import React, { useState, useRef } from 'react';
import { Image, Col, Row } from 'react-bootstrap';
import { CardWrapper } from './styled';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

export const PackageCard = ({
    imgSrc,
    name,
    options,
    customHandle,
    cardId,
    setCarChosen,
    setCardId,
    setIsEmpty,
    setIsChanged,
    setSelectPack,
    setPackId,
    value
}) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(name);
    const [selectedOption, setSelectedOption] = useState(
       null

    );
    const cardRef = useRef(null);
    const hoverRef = useRef(null);
    const [isHover, setIsHover] = useState(null);
    const containerRef = useRef(null);



    const handleOnChange = (optionValue) => {
        setFieldValue(name, optionValue ? optionValue.value : '');
        if (customHandle) {
            customHandle(optionValue);
        }
        field.onChange({
            target: {
                name: field.name,
                value: optionValue,
            },
        });
        hoverRef.current = optionValue;
        setSelectedOption(optionValue);
        setIsEmpty(false);
        setCarChosen(true);
        // setPackId(0);
    };

    const handleMouseEnter = (optionValue) => {
        hoverRef.current = optionValue;
    };

    const scrollToOption = (optionId) => {
        document.getElementById(optionId).scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        });
    };

    const selectedValue = (option) => {
        if(selectedOption === null){
            return option === value;
        }else{
            return selectedOption === option
        }
    }

    const carName = 'Polestar 2';

    const carType = (option) => {
        let type = '';
        switch (option) {
            case '181887':
                type = 'Standard Range Single Motor';
                break;
            case '181886':
                type = 'Long Range Single Motor';
                break;
            case '181885':
                type = 'Long Range Dual Motor';
                break;
            default:
                type = 'Standard Range Single Motor';
        }
        return type;
    };

    useEffect(() => {
        scrollToOption(`cars-${cardId}`);
    }, [cardId]);

    return (
        <CardWrapper
            isHover={isHover}
            hoverRef={hoverRef}
            selectedOption={selectedOption}
        >
            <div ref={cardRef} style={{ display: 'flex' }}>
                <div ref={containerRef} className="container-card-slider">
                    {options.map((option, i) => (
                        <Row
                            className={`container-display${
                                selectedValue(option.value)
                                    ? '-selected'
                                    : ''
                            }`}
                            onMouseEnter={() => {
                                handleMouseEnter(option.value);
                                setIsHover(option.value);
                            }}
                            onMouseLeave={() => setIsHover('')}
                            onClick={() => {
                                handleOnChange(option.value);
                                setCardId(i);
                                setSelectPack([]);
                            }}
                            key={option.value}
                            id={`cars-${i}`}
                        >

                            
                            <Col className="row-package">
                                {selectedValue(option.value) ? 
                            <Image
                                alt="icon"
                                src="/Images/check-orange.svg"
                                className="check-orange-icon"
                                width={'32px'}
                            /> : ''
                                }

                                <div className="container-img">
                                    <Image
                                        alt="polestar"
                                        src={imgSrc}
                                        className={
                                            selectedValue(option.value)
                                                ? 'img-display-hover'
                                                : 'img-display'
                                        }
                                    />
                                </div>
                                <div className="label-display">
                                    <h3>{carName}</h3>
                                    <h3>{carType(option.value)}</h3>
                                </div>
                                <p className={`${selectedValue(option.value) && 'transition-text'} select-text`} style={{width:'fit-content'}}>
                                   {selectedValue(option.value)?"Selected Vehicle":"Select Vehicle"}
                                    <span>
                                        <Image
                                            alt="icon"
                                            src="/Images/plus-polestar.svg"
                                            className="btn-choose"
                                        />
                                    </span>
                                </p>
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
        </CardWrapper>
    );
};
