import React from 'react';
import { theme } from '../../../../theme';
import styled from 'styled-components';

export const SliderWrapper = styled.div`
    display: none;
    width: 100%;
    margin-left: 25px;
    gap: 10px;
    ${theme.mediaQueriesMaxWidth.lg} {
        display: flex;
    }
`;

export const Slider = ({ activeIndex, totalImages, style }) => {
    const segmentWidth = 100 / totalImages;

    return (
        <SliderWrapper>
            {[...Array(totalImages)].map((_, index) => (
                <div
                    key={index}
                    style={{
                        width: `${segmentWidth}%`,
                        height: '2px',
                        backgroundColor:
                            index === activeIndex ? 'black' : 'gray', // Change color based on active index
                    }}
                />
            ))}
        </SliderWrapper>
    );
};
