import React from 'react';
import { connect } from 'react-redux';
import { MeterWrappper } from './MeterStyled';
export const MeterContainer = ({
    slug,
    annual,
    fortnight,
    isLoading,
    isCalculating,
    heading1,
    text1,
    heading2,
    text2,
    runningCost,
    carCost,
    shareOfSavings,
    perFortnight,
    selectPack,
}) => {
    var digits = annual
        .toString()
        .split('')
        .map((num) => (!isNaN(parseInt(num)) ? parseInt(num) : num));
    var digits2 = fortnight
        .toString()
        .split('')
        .map((num) => (!isNaN(parseInt(num)) ? parseInt(num) : num));
    if (digits.length < 4) {
        let padZeros = 4 - digits.length;
        digits = Array(padZeros).fill(0).concat(digits);
    }
    if (digits2.length < 3) {
        let padZeros = 3 - digits2.length;
        digits2 = Array(padZeros).fill(0).concat(digits2);
    }

    const renderAnnual = () => {
        return digits.map((number, i) => {
            return (
                <span id={`annual${i}`} key={i}>
                    {isCalculating ? (
                        <span
                            className={
                                i % 2 === 0
                                    ? 'numAnimate'
                                    : i % 3 === 0
                                    ? 'revNumAnimate'
                                    : 'lastNumAnimate'
                            }
                        ></span>
                    ) : (
                        number
                    )}
                </span>
            );
        });
    };
    const renderFortnight = () => {
        return digits2.map((number, i) => {
            return (
                <span id={`fortnight${i}`} key={i}>
                    {isCalculating > 0 ? (
                        <span
                            className={
                                i % 2 === 0
                                    ? 'numAnimate'
                                    : i % 3 === 0
                                    ? 'revNumAnimate'
                                    : 'lastNumAnimate'
                            }
                        ></span>
                    ) : (
                        number
                    )}
                </span>
            );
        });
    };
    return (
        <MeterWrappper>
            <p className="heading text">{heading1}</p>
            <div className="amount">
                <h4>$</h4>
                {selectPack?.length > 0 ? (
                    renderAnnual()
                ) : (
                    <>
                        <span>0</span>
                        <span>0</span>
                        <span>0</span>
                        <span>0</span>
                    </>
                )}
            </div>
            <p className="text-description">{text1}</p>
            <hr />
            <p className="heading text">{heading2}</p>
            <div className="amount">
                <h4>$</h4>
                {selectPack?.length > 0 ? (
                    renderFortnight()
                ) : (
                    <>
                        <span>0</span>
                        <span>0</span> <span>0</span>
                    </>
                )}
            </div>
            <p className="text-description">{text2}</p>
        </MeterWrappper>
    );
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.vehicle.isLoading,
        isCalculating: state.vehicle.isCalculating,
    };
};

export const Meter = connect(mapStateToProps)(MeterContainer);
