import styled from 'styled-components';


export const RangeSliderWrappper =  styled.div`
        font-weight: 400;

        p{
            font-size: 14px;
            color: #666666;
            
        }

        h3{
            font-size: 14px;
            line-height: none;
            color: #333333;
        }    


   
`;

