import styled from 'styled-components';

export const StyledCheckbox = styled.input`
    background: #f3f4f4;
    border: 1px solid #666666;
    border-radius: 0;
    color: #666666;
    margin-right: 10px;
    position: absolute;

    input[type='checkbox']:checked {
        visibility: hidden;
    }
`;

export const CheckboxLabel = styled.label`
    font-weight: 500;
    color: #666666;
    margin-left: 35px;
    position: relative;
`;
export const CheckboxContainer = styled.div`
    display: flex;
    margin-bottom: 40px;
    position: relative;
`;

export const CustomCheckbox = styled.div`
    width: 18px;
    height: 18px;
    background: #f3f4f4;
    border: 1px solid #666666;
    border-radius: 0;
    color: #666666;
    margin-right: 10px;
    position: absolute;

    &::before {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border: 2px solid transparent;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        opacity: 0;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 3px;
        width: 9px;
        height: 14px;
        border: 2px solid transparent;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
        opacity: ${(props) => (props.checked ? 1 : 0)};
        border-color: #666666; /* Icon color */
    }
`;

export const HiddenCheckbox = styled.input`
    display: none;
`;
