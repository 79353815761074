import React from 'react';
import { Image } from 'react-bootstrap';
import { PackCardWrapper, LabelCard, StandardCard } from './styled';
import { useField, useFormikContext } from 'formik';
import { ReactComponent as ToolTip } from './question-mark-circle.svg';



export const PackCard = ({
    pack,
    selectPack,
    selectPackHandle,
    name,
    value,
    setPackId,
    customHandle,
    index,
    packId
}) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(name);
    const isSelected = () => {
        let packIndex = selectPack?.findIndex((p) => p.pack === pack.pack);
        return packIndex >= 0;
    };
    const cardImage = {
        Pilot: '/Images/Pilot.png',
        Plus: '/Images/Plus.png',
        Performance: '/Images/Performance.png',
    };

    const handleChange = (pack) => {
        setFieldValue(name, pack ? pack.pack : '');
        field.onChange({
            target: {
                name: field.name,
                value: pack,
            },
        });

        if (customHandle) {
            customHandle(pack);
        }

        let prevValue = value;
        let packIndex = value?.findIndex((p) => p.pack === pack.pack);
        if (packIndex >= 0) {
            prevValue?.splice(packIndex, 1);
            setFieldValue(name, [...prevValue]);
        } else if (pack.pack === 'Standard') {
            prevValue = [];
            setFieldValue(name, prevValue);
        } else {
            const updatedPrevValue = [...prevValue, pack];
            setFieldValue(name, updatedPrevValue);
        }
    };




    return (
        <>
            {pack.pack === 'Standard' ? (
                <StandardCard
                    id={`pack-${index}`}
                    onClick={() => {
                        selectPackHandle(pack);
                        handleChange(pack);
                        setPackId(index);
                    }}
                    isSelected={isSelected()}
                >
                    {isSelected() && (
                        <Image
                            alt="icon"
                            src="/Images/check-orange.svg"
                            className="check-orange-icon"
                            width={'32px'}
                        />
                    )}
                    <h3 style={{maxWidth:'200px'}}>Standard Vehicle Pack </h3>
                    <span>No Additional Cost</span>
                </StandardCard>
            ) : (
                <PackCardWrapper
                id={`pack-${index}`}
                    onClick={() => {
                        selectPackHandle(pack);
                        handleChange(pack);
                        setPackId(index);
                    }}
                >
                    {isSelected() && (
                        <Image
                            alt="icon"
                            src="/Images/check-orange.svg"
                            className="check-orange-icon"
                            width={'32px'}
                        />
                    )}
                    <div
                        style={{
                            width: '100%',
                            height: '72%',
                            overflow: 'hidden',
                        }}
                    >
                        <Image
                            alt="pack-image"
                            src={cardImage[pack.pack]}
                            className={isSelected() ? "pack-image" : "unselect-pack-image"}
                            width={'100%'}
                            style={{
                                pointerEvents: isSelected() ? 'none' : '',
                            }}
                        />
                    </div>
                    <LabelCard>
                        <h4>
                            {pack.pack}{' '}
                            <div className="tooltip-container">
                                <ToolTip
                                    fill="gray"
                                    className="tooltip-icon"
                                />
                                <div
                                    className="tooltip-text"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <h5>{pack.pack} Pack</h5>
                                    <p>
                                        <a
                                            href="https://www.polestar.com/au/polestar-2/upgrades/"
                                            target="#blank"
                                        >
                                            Click here
                                        </a>{' '}
                                        for further detail on the available
                                        packs compatible with Polestar 2.
                                    </p>
                                </div>
                            </div>
                        </h4>
                    </LabelCard>
                    <p className={!isSelected() ? "unselect-p text" : "transition-text"}>
                        {isSelected()
                            ? 'Selected Package'
                            : 'Select Package'}
                        <span>
                            <Image
                                alt="icon"
                                src="/Images/plus-polestar.svg"
                                className="btn-choose"
                            />
                        </span>
                    </p>
                </PackCardWrapper>
            )}
        </>
    );
};

export default PackCard;
