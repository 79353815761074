import React from 'react';

export const PackSkeleton = () => {
    return (
        <div style={{ display: 'grid', position: 'relative' }}>
            <div
                style={{
                    width: '270px',
                    height: '270px',
                    background: '#D9D9D9',
                    margin: '12px 0',
                }}
            ></div>
            <div
                style={{
                    width: '270px',
                    height: '24px',
                    background: '#D9D9D9',
                    margin: '12px 0',
                }}
            ></div>
            <div
                style={{
                    width: '100px',
                    height: '24px',
                    background: '#D9D9D9',
                    margin: '12px 0',
                }}
            ></div>
        </div>
    );
};
